var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-9",
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            "align-self": "flex-end",
          },
        },
        [
          _c("date-picker", {
            staticStyle: { "max-width": "50%", "max-height": "10%" },
            attrs: {
              initValue: _vm.dateSelected,
              customLabel: _vm.dateFormatted,
            },
            on: { onChange: _vm.dateChanged },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-3",
          staticStyle: { display: "flex", "justify-content": "flex-end" },
        },
        [_c("matches-callendar")],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.matchesData,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("referee.summary.teamAssignments.noData"),
          },
          scopedSlots: _vm._u([
            {
              key: "cell(homeTeamName)",
              fn: function (data) {
                return [
                  _c("span", { staticClass: "text-no-wrap" }, [
                    _vm._v(_vm._s(data.item.homeTeamName)),
                  ]),
                ]
              },
            },
            {
              key: "cell(opponentTeamName)",
              fn: function (data) {
                return [
                  _c("span", { staticClass: "text-no-wrap" }, [
                    _vm._v(_vm._s(data.item.opponentTeamName)),
                  ]),
                ]
              },
            },
            {
              key: "cell(startTime)",
              fn: function (data) {
                return [_vm._v(_vm._s(_vm.parseTime(data.item.startTime)))]
              },
            },
            {
              key: "cell(field)",
              fn: function (data) {
                return [_vm._v(_vm._s(data.item.field))]
              },
            },
            {
              key: "cell(niv)",
              fn: function (data) {
                return [_vm._v(_vm._s(data.item.niv))]
              },
            },
            {
              key: "cell(location)",
              fn: function (data) {
                return [_vm._v(_vm._s(data.item.location.name))]
              },
            },
            {
              key: "cell(referees)",
              fn: function (data) {
                return [
                  data.item.referees.length > 0
                    ? _vm._l(data.item.referees, function (referee) {
                        return _c("div", { key: referee.id }, [
                          _c("span", [_vm._v(_vm._s(referee.name))]),
                        ])
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(canSignUp)",
              fn: function (data) {
                return [
                  data.item.canSignUp
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          on: {
                            click: function ($event) {
                              return _vm.openAssignmentPanel(data.item.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("referee.allMatches.signup")))]
                      )
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }