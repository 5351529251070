






























































import { RefereeMatch } from '@/modules/referees/models/referee-match.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import emitter from '@/shared/services/emitter';
import { DateHelper } from '@/shared/helpers/date.helper';

@Component({})
export default class AssignedMatchesList extends Vue
{
    isLoading = false;
    showHistory = false;
    matchToUnplan: RefereeMatch | null = null;
    showUnplanModal = false;
    
    fields: any[] =  [ 
        { key: 'date', label: this.$t('referee.summary.shared.date'), tdClass: 'date date-no-wrap vertical-align-middle' },
        { key: 'team', label: this.$t('referee.summary.shared.team'), tdClass: 'vertical-align-middle' },
        { key: 'opponent', label: this.$t('referee.summary.shared.opponent'), tdClass: 'vertical-align-middle' },
        { key: 'time', label: this.$t('referee.summary.shared.time'), tdClass: 'vertical-align-middle' },
        { key: 'field', label: this.$t('referee.summary.shared.field'), tdClass: 'vertical-align-middle' },
        { key: 'niv', label: this.$t('referee.summary.shared.niv'), tdClass: 'vertical-align-middle' },
        { key: 'points', label: this.$t('referee.summary.shared.points'), tdClass: 'vertical-align-middle' },
        { key: 'btn', label: '', tdClass: 'vertical-align-middle' }
    ];    

    @Getter
    refereeMatches!: RefereeMatch[];
        
    @Action
    private loadRefereeMatches!: (showHistory: boolean) => Promise<void>
    @Action
    private refereeUnplanMatch!: (matchId: string) => Promise<void>

    async created () : Promise<void> {
        await this.fetchMatches();

        emitter.on('referee-reload', () => {
            this.fetchMatches();
        });

        emitter.on('referee-matches-reload', () => {
            this.fetchMatches();
        });
    }

    async fetchMatches () : Promise<void> {
        await this.loadRefereeMatches(this.showHistory)
            .catch(() => {
                ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
            });
    }

    async unplan(): Promise<void> {
        await this.refereeUnplanMatch(this.matchToUnplan!.id)
            .catch(() => {
                ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
            });

        emitter.emit('referee-reload');
    }

    openUnplanMatchDialog (match: RefereeMatch): void {
        this.matchToUnplan = match;
        this.showUnplanModal;
        this.$bvModal.show('unplan-modal');
    }

    parseDate(date: string) : string {
        return DateHelper.getDate(date);
    }

    parseTime(time: string) : string {
        return DateHelper.parseTime(time);
    }
}
