var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "callendar" }, [
    _vm.statisticsLoading || _vm.allMatchesLoading
      ? _c("div", { staticClass: "loader-container" }, [_c("Loader")], 1)
      : _vm._e(),
    _c("div", { staticClass: "header" }, [
      _c("span", [
        _c("i", {
          staticClass: "fas fa-chevron-left clickable",
          on: {
            click: function ($event) {
              return _vm.subtractMonth()
            },
          },
        }),
      ]),
      _c("span", [_vm._v(_vm._s(_vm.currentDateFormatted))]),
      _c("span", [
        _c("i", {
          staticClass: "fas fa-chevron-right clickable",
          on: {
            click: function ($event) {
              return _vm.addMonth()
            },
          },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "weeks-header" },
          _vm._l(_vm.weeksHeaders, function (week, index) {
            return _c("div", { key: index, staticClass: "week-day-name" }, [
              _vm._v(" " + _vm._s(week) + " "),
            ])
          }),
          0
        ),
        _vm._l(_vm.weeks, function (week) {
          return _c(
            "div",
            { key: week.weekNumber, staticClass: "week" },
            _vm._l(week, function (day, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "day",
                  class: [
                    _vm.selectedDay === day.day &&
                    _vm.selectedMonth === day.month
                      ? "selected"
                      : "",
                    day.inMonth ? "clickable" : "",
                    day.beforeMonth || day.afterMonth ? "disabled" : "",
                  ],
                  style: { backgroundColor: day.background },
                  on: {
                    click: function ($event) {
                      return _vm.changeDate(day.day, day.month, day.year)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(day.label) + " ")]
              )
            }),
            0
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }