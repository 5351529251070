

























import { RefereeType } from '@/modules/referees/models/referee-type.model';
import { IRefereeApiService } from '@/modules/referees/services/referee-api.service';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class SelfAssignRefereeModal extends Vue {

    private loading!: boolean;
    submitting = false;
    
    refereeTypes: RefereeType[] = [];
    selectedRefereeType: RefereeType | null = null;

    @Prop() matchId!: string;

    @Getter
    clubId!: string;

    @inject()
    refereeApiService!: IRefereeApiService;

    async created () {
        await this.fetchRefereeTypes();
    }

    async fetchRefereeTypes () : Promise<void> {
        try {
            this.loading = true;

            const result = await this.refereeApiService.getRefereeTypes(this.clubId);
            this.refereeTypes = result;
        }
        catch {
            ToastHelper.showError(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedFailed'))
        }
        finally {
            this.loading = false;
        }
    }
    
    closeModal(): void {
        this.$emit('close', { shouldReload: false });
    }

    async submit () : Promise<void> {
        try {
            this.submitting = true;

            if (this.selectedRefereeType === null || !this.isFormValid) {
                return;
            }

            const result = await this.refereeApiService.selfAssignReferee(this.clubId, this.matchId, this.selectedRefereeType.id);

            if (!result) {
                ToastHelper.showError(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedFailed'));
                return;
            }

            ToastHelper.showSuccess(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedSuccess'));
            this.$emit('close', { shouldReload: true });
        }
        catch {
            ToastHelper.showError(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedFailed'));
        }
        finally {
            this.submitting = true;
        }
    }

    get isFormValid(): boolean {
        return !!this.selectedRefereeType;
    }

    get types() : RefereeType[] {
        return this.refereeTypes;
    }
}
