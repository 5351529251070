













































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RefereeType } from '../../models/referee-type.model';
import { TeamReferee } from '../../models/team-referee.model';
import { RefereeTeamMatch } from '../../models/referee-team-match.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { DateHelper } from '@/shared/helpers/date.helper';

@Component
export default class RefereeAssignmentModal extends Vue {
  @Prop()
  match!: RefereeTeamMatch;

  selectedRefereeType: RefereeType | null = null;
  selectedReferees: TeamReferee[] = [];

  @Getter
  teamReferees!: TeamReferee[];
  @Getter
  refereeTypes!: RefereeType[];

  @Action
  private setLoading!: (loading: boolean) => Promise<void>
  @Action
  private loadRefereeTypes!: () => Promise<void>
  @Action
  private loadTeamReferees!: (matchId: string) => Promise<void>
  @Action
  private assignReferees!: (payload: { matchId: string, refereeTypeId: string, refereesIds: string[]}) => Promise<void>

  async created(): Promise<void> {
    await Promise.all([
      this.loadRefereeTypes(),
      this.loadTeamReferees(this.match.id)
    ])

    this.selectedReferees = [...this.teamReferees.filter(x => this.match.referees.some(y => y.id === x.id))];
  }

  isFormValid(): boolean {
    return !!this.selectedRefereeType && this.selectedReferees.length <= 2;
  }

  closeModal(): void {
    this.$emit('close', { shouldReload: false });
  }

  async assign(): Promise<void> {
    if (!this.isFormValid)
      return;

    const payload = {
      matchId: this.match.id,
      refereeTypeId: this.selectedRefereeType!.id,
      refereesIds: this.selectedReferees.map(x => x.id)
    }
    

    try {
      await this.setLoading(true);
      await this.assignReferees(payload);
      ToastHelper.showSuccess(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedSuccess'));
      this.$emit('close', { shouldReload: true });
    } catch (ex) {
      ToastHelper.showError(this.$bvToast, this.$t('referee.summary.teamAssignments.assignedFailed'))
    } finally {
      await this.setLoading(false);
    }
  } 

  parseDate(date: string) : string {
      return DateHelper.getDate(date);
  }

  parseTime(time: string) : string {
      return DateHelper.parseTime(time);
  }
}
