


























import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { RefereeSummary } from '../models/referee-summary.model';
import AllMatches from './components/matches/AllMatches.vue';
import AssignedMatchesList from './components/matches/AssignedMatchesList.vue';
import TeamsMatchesList from './components/matches/TeamMatchesList.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component({
  components: {
    'all-matches': AllMatches,
    'assigned-matches': AssignedMatchesList,
    'teams-matches': TeamsMatchesList
  }
})
export default class Referee extends Vue {

  @Getter
  refereeSummary!: RefereeSummary | null;
  @Getter
  currentMemberIsReferee!: boolean;

  @Action
  private loadRefereeSummary!: () => Promise<void>;

  async created() : Promise<void> {
    if (this.currentMemberIsReferee) {
      await this.loadRefereeSummary()
        .catch(() => {
          ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
        });       
    }
  }
}
