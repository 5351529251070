var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("h5", { staticClass: "col-12 mt-3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("referee.allMatches.title"))),
    ]),
    _c("div", { staticClass: "col-12 mr-0" }, [_c("matches-list")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }