var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mb-3" },
    [
      _c("h5", { staticClass: "col-12 text-center mt-3" }, [
        _vm._v(_vm._s(_vm.$t("referee.summary.teamAssignments.modal.title"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "col-12 font-weight-bold mt-3 d-flex justify-content-around container",
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.parseDate(_vm.match.startUtc)))]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.match.homeTeamName) +
                " - " +
                _vm._s(_vm.match.awayTeamName)
            ),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("referee.summary.teamAssignments.modal.time")) +
                " " +
                _vm._s(_vm.parseTime(_vm.match.startUtc))
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "col-12 container mt-3 d-flex" }, [
        _c("div", { staticClass: "col-12 form-group" }, [
          _c("label", { attrs: { for: "refereeType" } }, [
            _vm._v(
              _vm._s(
                _vm.$t("referee.summary.teamAssignments.modal.refereeType")
              )
            ),
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedRefereeType,
                  expression: "selectedRefereeType",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "refereeType" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedRefereeType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }),
              _vm._l(_vm.refereeTypes, function (refereeType) {
                return _c(
                  "option",
                  { key: refereeType.id, domProps: { value: refereeType } },
                  [_vm._v(" " + _vm._s(refereeType.name) + " ")]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._l(
        _vm.teamReferees.filter(function (tr) {
          return tr.canBeAssigned
        }),
        function (referee) {
          return _c(
            "div",
            { key: referee.id, staticClass: "col-12 container mt-3 d-flex" },
            [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedReferees,
                      expression: "selectedReferees",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: referee,
                    checked: Array.isArray(_vm.selectedReferees)
                      ? _vm._i(_vm.selectedReferees, referee) > -1
                      : _vm.selectedReferees,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.selectedReferees,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = referee,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectedReferees = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectedReferees = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectedReferees = $$c
                      }
                    },
                  },
                }),
                _c("label", { staticClass: "form-check-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(referee.name) +
                      " (" +
                      _vm._s(referee.points) +
                      "pt, niv. " +
                      _vm._s(referee.levelCode) +
                      ") "
                  ),
                ]),
              ]),
            ]
          )
        }
      ),
      _vm.teamReferees.filter(function (tr) {
        return !tr.canBeAssigned
      }).length > 0
        ? _c("p", { staticClass: "col-12 mt-3" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("referee.summary.teamAssignments.modal.notAllowed")
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _vm._l(
        _vm.teamReferees.filter(function (tr) {
          return !tr.canBeAssigned
        }),
        function (referee) {
          return _c(
            "div",
            { key: referee.id, staticClass: "col-12 container mt-3 d-flex" },
            [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  staticClass: "form-check-input",
                  attrs: { disabled: "", type: "checkbox" },
                }),
                _c("label", { staticClass: "form-check-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(referee.name) +
                      " (" +
                      _vm._s(referee.points) +
                      "pt, niv. " +
                      _vm._s(referee.levelCode) +
                      ") "
                  ),
                ]),
              ]),
            ]
          )
        }
      ),
      _c(
        "div",
        { staticClass: "col-12 container mt-3 justify-content-between d-flex" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("referee.summary.shared.cancel")) + " "
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.isFormValid() },
              on: {
                click: function ($event) {
                  return _vm.assign()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("referee.summary.shared.submit")))]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }