































import { Vue, Component, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { IRefereeApiService } from '@/modules/referees/services/referee-api.service';
import MatchCalendarStatistics, { MatchCalendarStatisticsDay } from '@/modules/referees/models/match-calendar-statistics.model';
import { Getter } from 'vuex-class';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import emitter from '@/shared/services/emitter';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';


@Component
export default class AllMatchesCallendar extends Vue {

    public currentDateFormat = 'YYYY-MM-DD'; 
    public currentDate: string = dayjs().format(this.currentDateFormat);

    private statisticsLoading = false;
    private statisticsData: MatchCalendarStatistics | null = null;

    readonly weeksHeaders = [
        `${this.$t('referee.allMatches.weekdaysShort.monday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.tuesday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.wednesday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.thursday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.friday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.saturday')}`,
        `${this.$t('referee.allMatches.weekdaysShort.sunday')}`,
    ]

    @inject()
    refereeApiService!: IRefereeApiService;

    @Getter
    clubId!: string;
    @Getter
    allMatchesLoading!: boolean;
    @Getter
    allMatchesDate!: string;


    async created () {
        dayjs.extend(weekday);

        this.currentDate = this.allMatchesDate;
        await this.fetchStatistics();        

        emitter.on('referee-statistics-reload', () => {
            this.fetchStatistics();
        });
    }

    get statistics () : MatchCalendarStatisticsDay[] {
        if (this.statisticsData === null) {
            return [];
        }

        return this.statisticsData.days;
    }

    get selectedDay () : number {
        return dayjs(this.allMatchesDate, this.currentDateFormat).get('date');
    }

    get selectedMonth () : number {
        return dayjs(this.allMatchesDate, this.currentDateFormat).get('month') + 1;
    }

    get currentDateFormatted () : string {
        return dayjs(this.currentDate, this.currentDateFormat).format('MMMM YYYY');
    }
    
    get firstWeekdayInMonth () {
        const firstDay = `${this.currentYear}-${this.currentMonth}-01`;
        return dayjs(firstDay, this.currentDateFormat).weekday() + 1;
    }

    get currentDay () : number {
        return dayjs(this.currentDate, this.currentDateFormat).get('date')
    }

    get currentMonth () : number {
        return dayjs(this.currentDate, this.currentDateFormat).get('month') + 1;
    }

    get currentYear () : number {
        return dayjs(this.currentDate, this.currentDateFormat).get('year');
    }

    get daysInMonthCount () : number {
        return dayjs(this.currentDate, this.currentDateFormat).daysInMonth();
    }

    get weeks () {
        const weeks = [];

        let monthStarted = false;
        let monthEnded = false;

        let monthDay = 0;

        for (let w = 1; w <= 6; w++) {
            const week = [];

            for (let d = 1; d <= 7; d++) {

                if (!monthStarted && d >= this.firstWeekdayInMonth) {
                    monthDay = 1;
                    monthStarted = true;
                }
                else if (monthStarted && !monthEnded) {
                    monthDay += 1;
                }

                const month = !monthStarted ? this.currentMonth - 1 : (monthEnded ? this.currentMonth + 1 : this.currentMonth);
                const year = !monthStarted && this.currentMonth === 1 ? this.currentYear - 1 : (monthEnded && this.currentMonth === 12 ? this.currentYear + 1 : this.currentYear);

                const temp = {
                    label: monthDay ? monthDay.toString() : '',
                    day: monthDay,
                    month: month,
                    year: year,
                    weekdayNumber: d,
                    weekNumber: w,
                    beforeMonth: !monthStarted,
                    afterMonth: monthEnded,
                    inMonth: monthStarted && !monthEnded,
                    isFirstDay: monthDay === 1,
                    background: this.getDayColor(monthDay, month, this.currentYear)
                }

                week.push(temp);

                if (monthStarted && !monthEnded && monthDay >= this.daysInMonthCount) {
                    monthDay = 0;
                    monthEnded = true;
                }
            }

            weeks.push(week)
        }

        return weeks;
    }

    async subtractMonth () : Promise<void> {
        this.currentDate = (dayjs(this.currentDate).subtract(1, 'month')).format('YYYY-MM-DD');
        await this.fetchStatistics();
    }

    async addMonth () : Promise<void> {
        this.currentDate = dayjs(this.currentDate).add(1, 'month').format('YYYY-MM-DD');
        await this.fetchStatistics();
    }

    getDayColor(day: number, month: number, year: number) : string {

        const index = this.statistics.findIndex(x => 
            dayjs(x.date).get('date') === day &&
            dayjs(x.date).get('month') === month - 1 &&
            dayjs(x.date).get('year') === year)

        if (index === -1) {
            return 'lightgray';
        }

        const data = this.statistics[index];

        if (data.matchesTotalCount === 0) {
            return 'lightgray';
        }

        // All planned - green
        if (data.fullyPlannedCount + data.partlyPlannedCount + data.overplannedCount >= data.matchesTotalCount) {
            return '#8bc34a';
        }

        // Full free - red
        if (data.matchesTotalCount === data.unplannedCount) {
            return '#f44336';
        }

        // Minimal planned - orange
        if (data.fullyPlannedCount + data.partlyPlannedCount + data.overplannedCount >= (data.matchesTotalCount / 2)) {
            return '#ffc107';
        }

        // Almost nothing planned - yellow
        return "#ffeb3b";
    }

    changeDate (day: number, month: number, year: number) : void {
        const temp = this.allMatchesDate;
        const newDate = (dayjs(temp).set('date', day).set('month', month - 1).set('year', year)).format('YYYY-MM-DD');

        this.$store.commit('setAllMatchesDate', newDate);
    }

    async fetchStatistics () : Promise<void> {
        try {
            this.statisticsLoading = true;

            const result = await this.refereeApiService.getCalendarStatistics(this.clubId, this.currentYear, this.currentMonth);

            this.statisticsData = result;
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {
            this.statisticsLoading = false;
        }
    }

    @Watch('allMatchesDate')
    async allMatchesDateChanged(val: string, oldVal: string) : Promise<void> {
        
        if (dayjs(val).get('month') !== dayjs(oldVal).get('month') ||
            dayjs(val).get('year') !== dayjs(oldVal).get('year')) {
                this.currentDate = val;
                await this.fetchStatistics();
        }
    }
}
