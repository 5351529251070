import { render, staticRenderFns } from "./AllMatchesList.vue?vue&type=template&id=87166a82&scoped=true&"
import script from "./AllMatchesList.vue?vue&type=script&lang=ts&"
export * from "./AllMatchesList.vue?vue&type=script&lang=ts&"
import style0 from "./AllMatchesList.scss?vue&type=style&index=0&id=87166a82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87166a82",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/working_dir/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('87166a82')) {
      api.createRecord('87166a82', component.options)
    } else {
      api.reload('87166a82', component.options)
    }
    module.hot.accept("./AllMatchesList.vue?vue&type=template&id=87166a82&scoped=true&", function () {
      api.rerender('87166a82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/referees/pages/components/matches/AllMatchesList.vue"
export default component.exports