





























































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import emitter from '@/shared/services/emitter';
import { RefereeTeamMatch } from '@/modules/referees/models/referee-team-match.model';
import { RefereeForMatch } from '@/modules/referees/models/referee-for-match.model';
import RefereeAssignmentModal from '../../modals/RefereeAssignmentModal.vue';
import { DateHelper } from '@/shared/helpers/date.helper';

@Component({})
export default class TeamsMatchesList extends Vue 
{
    isLoading = false;
    showHistory = false;
    
    fields: any[] =  [ 
        { key: 'startUtc', label: this.$t('referee.summary.shared.date'), tdClass: 'date date-no-wrap vertical-align-middle' },
        { key: 'homeTeamDisplayName', label: this.$t('referee.summary.shared.team'), tdClass: 'vertical-align-middle' },
        { key: 'awayTeamDisplayName', label: this.$t('referee.summary.shared.opponent'), tdClass: 'vertical-align-middle' },
        { key: 'isMatchTimeKnown', label: this.$t('referee.summary.shared.time'), tdClass: 'vertical-align-middle' },
        { key: 'field', label: this.$t('referee.summary.shared.field'), tdClass: 'vertical-align-middle' },
        { key: 'niv', label: this.$t('referee.summary.shared.niv'), tdClass: 'vertical-align-middle' },
        { key: 'referees', label: this.$t('referee.summary.shared.referees'), tdClass: 'vertical-align-middle' } ]
    
    @Getter
    refereeTeamMatches!: RefereeTeamMatch[];

    @Action
    private loadRefereeTeamMatches!: (showHistory: boolean) => Promise<void>

    async created () : Promise<void> {
        await this.fetchMatches();

        emitter.on('referee-reload', () => {
            this.fetchMatches();
        });
    }

    async fetchMatches () : Promise<void> {
        this.isLoading = true;
        await this.loadRefereeTeamMatches(this.showHistory)
            .catch(() => {
                ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
            })
            .finally(() => {
                this.isLoading = false;
            });    
    }

    getRefereesNames(referees: RefereeForMatch[]): string {
        const names = referees.map(r => r.name);
        return names.join(', ');
    }

    openAssignmentPanel(match: RefereeTeamMatch): void {
        this.$modal.show(
            RefereeAssignmentModal,
            { match: match },
            { },
            { 'before-close': this.onModalClose }
        )
    }

    async onModalClose (e: { params: { shouldReload: boolean } }) : Promise<void> {
        if (e.params?.shouldReload) {
            emitter.emit('referee-reload');
            await this.fetchMatches();
        }
    }

    parseDate(date: string) : string {
        return DateHelper.getDate(date);
    }

    parseTime(time: string) : string {
        return DateHelper.parseTime(time);
    }
}
