









import { Vue, Component } from 'vue-property-decorator';

import AllMatchesList from './AllMatchesList.vue';
import AllMatchesCallendar from './AllMatchesCallendar.vue';

@Component({
    components: {
        'matches-list': AllMatchesList,
        'matches-callendar': AllMatchesCallendar
    }
})
export default class AllMatches extends Vue {

}
