var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "col-9 mt-3 mb-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("referee.summary.teamAssignments.title"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-3 mt-3 custom-control custom-switch justify-content-end",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showHistory,
                expression: "showHistory",
              },
            ],
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "team-assigment-history-switch" },
            domProps: {
              checked: Array.isArray(_vm.showHistory)
                ? _vm._i(_vm.showHistory, null) > -1
                : _vm.showHistory,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.showHistory,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showHistory = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showHistory = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showHistory = $$c
                  }
                },
                function ($event) {
                  return _vm.fetchMatches()
                },
              ],
            },
          }),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "team-assigment-history-switch" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("referee.summary.shared.showHistory")))]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.refereeTeamMatches,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("referee.summary.teamAssignments.noData"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(startUtc)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm.parseDate(row.item.startUtc)) + " "),
                ]
              },
            },
            {
              key: "cell(homeTeamDisplayName)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.homeTeamDisplayName) + " "),
                ]
              },
            },
            {
              key: "cell(awayTeamDisplayName)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.awayTeamDisplayName) + " "),
                ]
              },
            },
            {
              key: "cell(isMatchTimeKnown)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        row.item.isMatchTimeKnown
                          ? _vm.parseTime(row.item.startUtc)
                          : "-"
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "cell(field)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.field) + " ")]
              },
            },
            {
              key: "cell(niv)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.niv) + " ")]
              },
            },
            {
              key: "cell(referees)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.getRefereesNames(row.item.referees)) + " "
                  ),
                  _c("br"),
                  row.item.canAssign
                    ? _c(
                        "span",
                        {
                          staticClass: "select-teammembers",
                          on: {
                            click: function ($event) {
                              return _vm.openAssignmentPanel(row.item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "(" +
                              _vm._s(
                                _vm.$t(
                                  "referee.summary.teamAssignments.selectMembers",
                                  { count: 2 }
                                )
                              ) +
                              ") "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }