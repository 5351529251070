






















































import { ClubMatch } from '@/modules/referees/models/referee-matches-all.model';
import { IRefereeApiService } from '@/modules/referees/services/referee-api.service';
import { inject } from 'inversify-props';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import SelfAssignRefereeModal from './SelfAssignRefereeModal.vue';
import AllMatchesCallendar from "@/modules/referees/pages/components/matches/AllMatchesCallendar.vue";
import emitter from '@/shared/services/emitter';
import dayjs from 'dayjs';

@Component({
  components: {
    'matches-callendar': AllMatchesCallendar
  }
})
export default class AllMatchesList extends Vue {

    private matches: ClubMatch[] = [];
    isLoading = false;

    private initDate: string = dayjs().format('YYYY-MM-DD');
    private selectedDate: string = dayjs().format('YYYY-MM-DD');

    readonly fields = [
        { key: 'homeTeamName', label: `${this.$t('referee.allMatches.table.homeTeam')}` },
        { key: 'opponentTeamName', label: `${this.$t('referee.allMatches.table.opponentTeam')}` },
        { key: 'startTime', label: `${this.$t('referee.allMatches.table.time')}` },
        { key: 'field', label: `${this.$t('referee.allMatches.table.field')}` },
        { key: 'niv', label: `${this.$t('referee.allMatches.table.niv')}` },
        { key: 'location', label:`${this.$t('referee.allMatches.table.location')}` },
        { key: 'referees', label: `${this.$t('referee.allMatches.table.referees')}` },
        { key: 'canSignUp', label: `` },
    ];

    @inject()
    refereeApiService!: IRefereeApiService;

    @Getter
    clubId!: string;
    @Getter
    allMatchesLoading!: boolean;
    @Getter
    allMatchesDate!: string;

    async created () : Promise<void>  {
        await this.fetchMatches();
    }

    async fetchMatches () : Promise<void> {
        try {
            this.$store.commit('setAllMatchesLoading', true);
            this.isLoading = true;

            const response = await this.refereeApiService.getAllMatchesForReferee(this.clubId, this.allMatchesDate);

            this.matches = response.matches;
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {
            this.$store.commit('setAllMatchesLoading', false);
            this.isLoading = false;
        }
    }

    parseTime (time: string | null) {
        if (time === null) {
            return '';
        }
        
        const temp = time.split(':');
        return `${temp[0]}:${temp[1]}`;
    }

    openAssignmentPanel(matchId: string): void {
        this.$modal.show(
            SelfAssignRefereeModal,
            { matchId: matchId },
            { },
            { 'before-close': this.onModalClose }
        )
    }

    async onModalClose (e: { params: { shouldReload: boolean } }) : Promise<void> {
        if (e.params?.shouldReload) {
            emitter.emit('referee-statistics-reload');
            emitter.emit('referee-matches-reload');
            await this.fetchMatches();
        }
    }

    async dateChanged (val: string) {
        this.$store.commit('setAllMatchesDate', val);
    }

    get dateFormatted () : string {
        return `${dayjs(this.allMatchesDate, 'YYYY-MM-DD').format('D-M-YYYY')} (${this.matches.length})`;
    }

    get dateSelected () : string {
        return this.selectedDate;
    }

    get loading () : boolean {
        return this.isLoading || this.allMatchesLoading;
    }

    get matchesData () : ClubMatch[] {
        return this.matches;
    }
    
    @Watch('allMatchesDate')
    async allMatchesDateChanged() : Promise<void> {
        await this.fetchMatches();
    }
}
