var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.currentMemberIsReferee
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("h5", { staticClass: "col-12 mt-3" }, [
              _vm._v(_vm._s(_vm.$t("referee.summary.title"))),
            ]),
          ]),
          _c("div", { staticClass: "col-6" }, [
            _vm.refereeSummary !== null
              ? _c("h6", { staticClass: "col-12 mt-3 text-right" }, [
                  _vm._v(
                    _vm._s(_vm.$t("referee.summary.totalPointsLabel")) +
                      " " +
                      _vm._s(_vm.refereeSummary.pointsInSeason) +
                      "/" +
                      _vm._s(_vm.refereeSummary.targetPointsInSeason) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm.$t("referee.summary.level")) +
                      " " +
                      _vm._s(_vm.refereeSummary.level) +
                      " (" +
                      _vm._s(_vm.refereeSummary.levelCode) +
                      ") "
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.currentMemberIsReferee ? _c("assigned-matches") : _vm._e(),
        _c("teams-matches"),
        _vm.currentMemberIsReferee ? _c("all-matches") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }